import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  createAssistant(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.ASSISTANT_CREATE,
        data,
      })
      .then((res) => res.data);
  },

  messageAssistant(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.ASSISTANT_MESSAGE,
        data,
      })
      .then((res) => res.data);
  },
};
